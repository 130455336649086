import React from 'react'
import { redirect } from 'react-router-dom';
const SellersDetails = ({ prevStep, nextStep, handleChange, values, presets }) => {
  
  // for continue event listener
  const Continue = e => {

    var sellersName = document.getElementById('sellersName').value;
    var sellersCompanyName = document.getElementById('sellersCompanyName').value;
    var sellersExistingCustomer1 = document.getElementById('sellersExistingCustomer1').value;
    var sellersExistingCustomer2 = document.getElementById('sellersExistingCustomer2').value;
    var productOutcomeMetrics = document.getElementById('productOutcomeMetrics').value;
    var productValueProp = document.getElementById('productValueProp').value;

    if (sellersName && 
        sellersCompanyName && 
        sellersExistingCustomer1 &&  
        sellersExistingCustomer2  &&  
        productOutcomeMetrics &&
        productValueProp
    ) {
        e.preventDefault();
        nextStep();
        return
    }
        return
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  return (
    <div className="section homepage-hero wf-section">
        <div className="container">
            <h1 className="h1 centered">Tell Us About Yourself</h1>
            <p className="hero-subheading centered">Please tell us a little bit about who is selling this product.</p>
            <div className="form-component w-form" style={{ 'padding': '10px', 'backgroundColor': 'white'}}>
                    <form id="email-form" name="email-form" data-name="Email Form" method="get" action="#" className="contact-form" aria-label="Email Form">
                        <div className="form-field-container">
                            <label htmlFor="sellersName" className="form-field-label">Full Name</label>
                            <input 
                                id="sellersName"
                                onChange={handleChange('sellersName')}
                                onClick={(e) => {
                                    if (presets.sellersName === e.target.value) {
                                        e.target.value='';
                                    }
                                }}
                                defaultValue={values.sellersName} type="text" className="form-field w-input" maxLength="256" name="code" data-name="Code" placeholder="What is the BDR/SDR's full name?" 
                                required={true}/>
                        </div> 
                        <div className="form-field-container">
                            <label htmlFor="code" className="form-field-label">Company Name</label>
                            <input 
                                id="sellersCompanyName"
                                onChange={handleChange('sellersCompanyName')}
                                onClick={(e) => {
                                    if (presets.sellersCompanyName === e.target.value) {
                                        e.target.value='';
                                    }
                                }}
                                defaultValue={values.sellersCompanyName} type="text" className="form-field w-input" maxLength="256" name="code" data-name="Code" placeholder="The company that sells the product." 
                                required={true}/>
                        </div> 
                        <div className="form-field-container">
                            <label htmlFor="code" className="form-field-label">What does your product do?</label>
                            <textarea 
                                id="productValueProp" 
                                name="productValueProp" 
                                required={true} 
                                maxLength="5000"  
                                data-name="productValueProp" 
                                placeholder="Explain the problem your product solves."
                                defaultValue={values.productValueProp}
                                onClick={(e) => {
                                    if (presets.productValueProp === e.target.value) {
                                        e.target.value='';
                                    }
                                }}
                                onChange={handleChange('productValueProp')}
                                className="form-field message w-input"
                                style={{'height':'100px'}}>
                            </textarea>
                        </div>
                        <div className="form-field-container">
                            <label htmlFor="code" className="form-field-label w-form-label">What metrics does your product improve for your customers?</label>
                            <textarea 
                                id="productOutcomeMetrics" 
                                name="productOutcomeMetrics" 
                                required={true} 
                                maxLength="5000"  
                                data-name="productOutcomeMetrics" 
                                placeholder="example: Increase revenue 50% or decrease costs 95%!"
                                defaultValue={values.productOutcomeMetrics}
                                onClick={(e) => {
                                    if (presets.productOutcomeMetrics === e.target.value) {
                                        e.target.value='';
                                    }
                                }}
                                onChange={handleChange('productOutcomeMetrics')}
                                className="form-field message w-input"
                                style={{'height':'100px'}}>
                            </textarea>
                        </div>
                        <div className="form-field-container">
                            <label htmlFor="code" className="form-field-label">Who is your most impressive customer?</label>
                            <input 
                                id="sellersExistingCustomer1"
                                onChange={handleChange('sellersExistingCustomer1')}
                                onClick={(e) => {
                                    if (presets.sellersExistingCustomer1 === e.target.value) {
                                        e.target.value='';
                                    }
                                }}
                                defaultValue={values.sellersExistingCustomer1} type="text" className="form-field w-input" 
                                maxLength="256" name="code" data-name="Code" placeholder="The name of an existing relevant customer." required={true}/>
                        </div>  
                        <div className="form-field-container">
                            <label htmlFor="code" className="form-field-label">Who is your next most impressive customer?</label>
                            <input 
                                id="sellersExistingCustomer2"
                                onChange={handleChange('sellersExistingCustomer2')}
                                onClick={(e) => {
                                    if (presets.sellersExistingCustomer2 === e.target.value) {
                                        e.target.value='';
                                    }
                                }}
                                defaultValue={values.sellersExistingCustomer2} type="text" className="form-field w-input" maxLength="256" name="code" data-name="Code" placeholder="The name of an existing relevant customer." required={true}/>
                        </div>
                        <div className="buttons-container centered">
                            <input type="submit" value="Back" data-wait="Please wait..." className="button w-button secondary right-margin" onClick={ Previous }></input>
                            <input type="submit" value="Next" data-wait="Please wait..." className="button w-button primary" onClick={ Continue }></input>
                        </div>
                    </form>
                    <div className="contact-success-message w-form-done" tabIndex="-1" role="region" aria-label="Email Form success">
                        <div className="contact-success-text">Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail" tabIndex="-1" role="region" aria-label="Email Form failure">
                        <div className="error-text">Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default SellersDetails