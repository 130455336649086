import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate}
    from 'react-router-dom';
import Home from './pages/index';
import Denied from './pages/denied'
import './App.css';

function App() {
  return (
      <Router>
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/denied' element={<Denied />} />
            <Route path="/demo" element={
                <Navigate to="/"/>
            }/>
        </Routes>
      </Router>
  );
}

export default App;

