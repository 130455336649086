import React from 'react'
  
const Denied = () => {
    return (
        <div className="section homepage-hero wf-section">
    <div className="container">
      <h1 className="h1 centered">Access Code Denied</h1>
      <p className="hero-subheading centered">Sorry, that access code was not correct.</p>
      <div className="buttons-container primary centered">
          <a href="/" className="button primary w-button">Try Again</a>
      </div>
    </div>
  </div>
      );
};
  
export default Denied;