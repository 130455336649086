import React, { Component } from 'react'
import Home from './Home'
import AccessCode from './AccessCode'
import SellersDetails from './SellersDetails'
import ContactDetails from './ContactDetails'
import Success from './Success'

export default class DemoForm extends Component {

  presets = {
    step: 1,
    accessCode: '',
    sellersName: 'Duy T.', 
    sellersCompanyName: 'HeroPower', 
    sellersExistingCustomer1: 'Batman', 
    sellersExistingCustomer2: 'Iron Man', 
    productOutcomeMetrics: 'Our technology solutions to enable and empower superheroes that may not have strong enough super powers.  This accounts for a 35% reduced injury rate and a 20% increased capture rate.', 
    productValueProp: 'Leverage gadgets that are complimentary to your existing powers to make your missions more effective and yield more positive outcomes that keep crime off the streets.',
    contactUsers: 'Avengers and Marvel Superheroes',
    contactName: 'Captain America', 
    contactTitle: 'Avenger Executive', 
    contactCompany: 'S.H.I.E.L.D.', 
    contactChallenge: 'Ensuring that the Hydra organization is not able to take over the world.', 
  };

  state = {
    step: 1,
    accessCode: '',
    sellersName: 'Duy T.', 
    sellersCompanyName: 'HeroPower', 
    sellersExistingCustomer1: 'Batman', 
    sellersExistingCustomer2: 'Iron Man', 
    productOutcomeMetrics: 'Our technology solutions to enable and empower superheroes that may not have strong enough super powers.  This accounts for a 35% reduced injury rate and a 20% increased capture rate.', 
    productValueProp: 'Leverage gadgets that are complimentary to your existing powers to make your missions more effective and yield more positive outcomes that keep crime off the streets.',
    contactUsers: 'Avengers and Marvel Superheroes',
    contactName: 'Captain America', 
    contactTitle: 'Avenger Executive', 
    contactCompany: 'S.H.I.E.L.D.', 
    contactChallenge: 'Ensuring that the Hydra organization is not able to take over the world.', 
  };

  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
    window.scrollTo(0, 0);
  }

  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
    window.scrollTo(0, 0);
  }

  // Handle fields change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  }

  render() {
    const { step } = this.state;
    const { accessCode, sellersName, sellersCompanyName, sellersExistingCustomer1, sellersExistingCustomer2, productOutcomeMetrics, productValueProp, contactUsers, contactName, contactTitle, contactCompany, contactChallenge } = this.state;
    const values = { accessCode, sellersName, sellersCompanyName, sellersExistingCustomer1, sellersExistingCustomer2, productOutcomeMetrics, productValueProp, contactUsers, contactName, contactTitle, contactCompany, contactChallenge };
    const presets = this.presets;

    
    switch(step) {
      case 1: 
      return (
        <Home 
          nextStep={ this.nextStep }
          handleChange={ this.handleChange }
          values={ values }
        />
      )
      case 2: 
        return (
          <AccessCode 
            nextStep={ this.nextStep }
            handleChange={ this.handleChange }
            values={ values }
          />
        )
        case 3: 
        return (
          <SellersDetails 
            prevStep={ this.prevStep }
            nextStep={ this.nextStep }
            handleChange={ this.handleChange }
            values={ values }
            presets={ presets }
          />
        )
        case 4: 
        return (
          <ContactDetails 
            prevStep={ this.prevStep }
            nextStep={ this.nextStep }
            handleChange={ this.handleChange }
            values={ values }
            presets={ presets }
          />
        )
        case 5: 
          return (
            <Success values={values} />
          )
      default: 
          // do nothing
    }
  }
}