import React, { useEffect } from 'react'
import { redirect } from "react-router-dom";
const Home = ({ nextStep, handleChange, values }) => {

    useEffect(() => {
        // if using the older heroku app domain, redirect to CNAME subdomain
        if ("try-copymat-client.herokuapp.com" === window.location.hostname) {
            window.location.assign('https://demo.copymat.ai');
        }
    }, []);

    // for continue event listener
    const Continue = e => {
        nextStep();
    }

    return (
        <div className="section homepage-hero wf-section">
            <div className="container">
                <h1 className="h1 centered">Try Writing Great Outbound</h1>
                <p className="hero-subheading centered">Try our demo and become the Orwell of Outbound. <br /> Write great messaging that resonates.</p>
                <div className="buttons-container primary centered">
                    <input type="submit" value="Try demo now" data-wait="Please wait..." className="button primary w-button" onClick={Continue}></input>
                </div>
            </div>
        </div>
    )
}

export default Home