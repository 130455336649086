import React from 'react'
import { redirect } from 'react-router-dom';
const AccessCode = ({ nextStep, handleChange, values }) => {
  
  // for continue event listener
  const Continue = e => {
    var code = document.getElementById('code').value.trim();
    if (!code) {
        return
    }
    if (code !== "Savack") {
        e.preventDefault();
        window.location.replace("/denied");
        return
    }
    nextStep();
  }

  return (
    <div className="section homepage-hero wf-section">
        <div className="container">
            <h1 className="h1 centered">Access Code Required:</h1>
            <p className="hero-subheading centered">Please enter the access code you were given below. <br /> If you are interested in connecting, please contact support@copymat.com</p>
            <div className="form-component w-form" style={{ 'padding': '10px', 'backgroundColor': 'white'}}>
                    <form id="email-form" name="email-form" data-name="Email Form" method="get" action="#" className="contact-form" aria-label="Email Form">
                        <div className="form-field-container">
                            <label htmlFor="code" className="form-field-label">Access Code</label>
                            <input 
                                id="code"
                                onChange={handleChange('accessCode')}
                                defaultValue={values.accessCode} type="text" className="form-field w-input" maxLength="256" name="code" data-name="Code" placeholder="Please Enter Your Access Code" required={true}/>
                        </div>  
                        <div className="buttons-container primary centered">
                        <input type="submit" value="Next" data-wait="Please wait..." className="button primary w-button" onClick={ Continue }></input>

                        </div>
                    </form>
                    <div className="contact-success-message w-form-done" tabIndex="-1" role="region" aria-label="Email Form success">
                        <div className="contact-success-text">Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail" tabIndex="-1" role="region" aria-label="Email Form failure">
                        <div className="error-text">Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default AccessCode