import React from 'react'
const ContactDetails = ({ prevStep, nextStep, handleChange, values, presets }) => {
  
  // for continue event listener
  const Continue = e => {

    var contactName = document.getElementById('contactName').value;
    var contactTitle = document.getElementById('contactTitle').value;
    var contactCompany = document.getElementById('contactCompany').value;
    var contactChallenge = document.getElementById('contactChallenge').value;
    var contactUsers = document.getElementById('contactUsers').value;

    if (contactName && 
        contactTitle && 
        contactCompany && 
        contactChallenge && 
        contactUsers
    ) {
        e.preventDefault();
        nextStep();
        return
    }
        return
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  return (
    <div className="section homepage-hero wf-section">
        <div className="container">
            <h1 className="h1 centered">Who is the Contact?</h1>
            <p className="hero-subheading centered">Please tell us about who you are asking to buy your product.</p>
            <div className="form-component w-form" style={{ 'padding': '10px', 'backgroundColor': 'white'}}>
                    <form id="email-form" name="email-form" data-name="Email Form" method="get" action="#" className="contact-form" aria-label="Email Form">
                        <div className="form-field-container">
                            <label htmlFor="contactName" className="form-field-label">Contact's Full Name</label>
                            <input 
                                id="contactName"
                                onChange={handleChange('contactName')}
                                defaultValue={values.contactName} 
                                type="text" 
                                className="form-field w-input" 
                                maxLength="256" name="code" data-name="Code" 
                                placeholder="What is the full name of the contact?" 
                                onClick={(e) => {
                                    if (presets.contactName === e.target.value) {
                                        e.target.value='';
                                    }
                                }}
                                required={true}/>
                        </div> 
                        <div className="form-field-container">
                            <label htmlFor="contactTitle" className="form-field-label">Contact's Title</label>
                            <input 
                                id="contactTitle"
                                onChange={handleChange('contactTitle')}
                                onClick={(e) => {
                                    if (presets.contactTitle === e.target.value) {
                                        e.target.value='';
                                    }
                                }}
                                defaultValue={values.contactTitle} type="text" className="form-field w-input" maxLength="256" name="code" data-name="Code" placeholder="What is their title/role?" required={true}/>
                        </div> 
                        <div className="form-field-container">
                            <label htmlFor="contactCompany" className="form-field-label">Contact's Company</label>
                            <input 
                                id="contactCompany"
                                onChange={handleChange('contactCompany')}
                                onClick={(e) => {
                                    if (presets.contactCompany === e.target.value) {
                                        e.target.value='';
                                    }
                                }}
                                defaultValue={values.contactCompany} type="text" className="form-field w-input" maxLength="256" name="code" data-name="Code" placeholder="Who does the contact work for?" required={true}/>
                        </div> 
                        <div className="form-field-container">
                            <label htmlFor="contactChallenge" className="form-field-label">What is the #1 challenge the Contact is facing that you can help with?</label>
                            <textarea 
                                id="contactChallenge" 
                                name="contactChallenge" 
                                required={true} 
                                maxLength="5000"  
                                data-name="contactChallenge" 
                                placeholder="Explain the problem the contact has."
                                onClick={(e) => {
                                    if (presets.contactChallenge === e.target.value) {
                                        e.target.value='';
                                    }
                                }}
                                defaultValue={values.contactChallenge}
                                onChange={handleChange('contactChallenge')}
                                className="form-field message w-input"
                                style={{'height':'100px'}}>
                            </textarea>
                        </div>
                        <div className="form-field-container">
                            <label htmlFor="contactUsers" className="form-field-label">What roles will be using your product at the Contact's company?</label>
                            <input 
                                id="contactUsers"
                                onChange={handleChange('contactUsers')}
                                onClick={(e) => {
                                    if (presets.contactUsers === e.target.value) {
                                        e.target.value='';
                                    }
                                }}
                                defaultValue={values.contactUsers} type="text" className="form-field w-input" maxLength="256" name="code" data-name="Code" placeholder="Who would actually use the product everyday? e.g. roles/titles." required={true}/>
                        </div>
                        <div className="buttons-container centered">
                            <input type="submit" value="Back" data-wait="Please wait..." className="button w-button secondary right-margin" onClick={ Previous }></input>
                            <input type="submit" value="Next" data-wait="Please wait..." className="button w-button primary" onClick={ Continue }></input>
                        </div>
                    </form>
                    <div className="contact-success-message w-form-done" tabIndex="-1" role="region" aria-label="Email Form success">
                        <div className="contact-success-text">Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail" tabIndex="-1" role="region" aria-label="Email Form failure">
                        <div className="error-text">Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default ContactDetails