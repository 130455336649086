import React, { useState } from 'react';
import DotLoader from "react-spinners/DotLoader";
import Typist from 'react-typist-component';

const Success = ({ values }) => {

    let [loading, setLoading] = useState(false);
    let [text, setText] = useState("");
    let [currentTextCounter, setCurrentTextCounter] = useState(0);

    // for continue event listener
    const GenerateMessaging = async (size) => {
        await (async () => {
            document.getElementById('generate_short_message_button').disabled = true;
            document.getElementById('generate_short_message_button').style.display = "none";
            document.getElementById('generate_long_message_button').disabled = true;
            document.getElementById('generate_long_message_button').style.display = "none";
            document.getElementById('text_container').style.display = "none";
            setLoading(!loading);
            document.getElementById('loading_message').style.display = "block";
        })();

        var lline1 = "Write me a long outbound email to " + values.contactName + ", the " + values.contactTitle + " at " + values.contactCompany + ". Use a funny and creative writing style. Do not mention AI. Do not ask them if they are doing well.\n";
        var lline2 = "Are they struggling with " + values.contactChallenge + "?\n";
        var lline3 = "Companies have " + values.productOutcomeMetrics + " by using " + values.sellersCompanyName + ". " + values.sellersCompanyName + " works with companies like " + values.sellersExistingCustomer1 + " and " + values.sellersExistingCustomer2 + ".\n";
        var lline4 = values.sellersCompanyName + " allows " + values.contactUsers + " to " + values.productValueProp + ".\n";
        var lline5 = "Ask them if they would like to learn more and then end the email. Sign the email with Thanks, " + values.sellersName + ".\n";

        var sline1 = "Write me a short outbound email that includes all the information below to " + values.contactName + ", the " + values.contactTitle + " at " + values.contactCompany + ". Keep the email to 120 words or less.  Do not mention AI. Do not ask them if they are doing well.\n";
        var sline2 = "Are they struggling with " + values.contactChallenge + "?\n";
        var sline3 = "Companies have " + values.productOutcomeMetrics + " by using " + values.sellersCompanyName + ". " + values.sellersCompanyName + " works with companies like " + values.sellersExistingCustomer1 + " and " + values.sellersExistingCustomer2 + ".\n";
        var sline4 = values.sellersCompanyName + " allows " + values.contactUsers + " to " + values.productValueProp + ".\n";
        var sline5 = "Ask them if they could see themselves deriving similar benefit and then end the email. Sign the email with just '-" + values.sellersName + "'.\n";

        var payload = "";
        if (size == "short") {
            payload = sline1 + sline2 + sline3 + sline4 + sline5;
        } else {
            payload = lline1 + lline2 + lline3 + lline4 + lline5;
        }

        let response;
        try {
            response = await fetch('https://try-copymat-server.herokuapp.com/demo', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    {
                        "prompt": payload,
                    }
                )
            });

            response = await response.json();
        } catch (error) {
            console.error('Error:', error);
        }

        await (async () => {
            setLoading(loading);
            document.getElementById('loading_message').style.display = "none";
            // document.getElementById('text').innerText='';
            document.getElementById('text_container').style.display = "block";

            // fix the new line chars that are inconsistent in the response payload
            var str = response.data;
            var result = '';
            for (var i = 0; i < str.length; i++) {

                // check if the current char is '\n'
                if (str[i] === '\n') {

                    // remove the first '\n' char, it's silly
                    if (i == 0) {
                        continue;
                    }

                    // add a double '\n' to the final result
                    result += "\n\n";

                    // if the next char is a '\n', skip it
                    if (str[i + 1] === '\n') {
                        i++;
                        continue;
                    }

                    // we don't need to append more '\n' to the final result
                    continue;
                }
                // append the current char to the final result string
                result += str[i];
            }

            // remove the last '\n' character, since its a sign-off
            for (var i = result.length - 1; i >= 0; i--) {
                if (result[i] === '\n') {
                    result = result.slice(0, i) + result.slice(i + 1);
                    break;
                }
            }

            setText(result);
            setCurrentTextCounter(currentTextCounter + 1)

            document.getElementById('generate_short_message_button').textContent = "Write Short Message";
            document.getElementById('generate_short_message_button').disabled = false;
            document.getElementById('generate_short_message_button').style.display = "block";
            document.getElementById('generate_long_message_button').textContent = "Write Long Message";
            document.getElementById('generate_long_message_button').disabled = false;
            document.getElementById('generate_long_message_button').style.display = "block";
        })();
    }

    return (
        <div className="section homepage-hero wf-section">
            <DotLoader
                color="#175cff"
                loading={loading}
                size={150}
            />
            <div className="hero-subheading centered" style={{ 'width': '100%', 'padding': '10px', 'paddingTop': '50px', 'backgroundColor': 'white' }}>
                <div id="text_container" style={{ 'display': 'none', 'width': '100%', 'minHeight': '300px', 'whiteSpace': 'pre-wrap', 'textAlign': 'left', 'padding': '20px', 'border': '1px solid lightgrey' }}>
                    <Typist

                        id={'text'}
                        key={currentTextCounter}
                        typingDelay={10}
                    >
                        {text}
                    </Typist>
                </div>
                <p id="loading_message" style={{ 'display': 'none', 'textAlign': 'center' }} className="centered">Please wait... <br />Copymat is using AI to write quality messaging...</p>
            </div>
            <div className="buttons-container centered">
                <button id="generate_short_message_button" className="button primary right-margin w-button" disabled={false} value="short" onClick={() => GenerateMessaging('short')}>Write Short Message</button>
                <button id="generate_long_message_button" className="button w-button secondary" disabled={false} value="long" onClick={() => GenerateMessaging('long')}>Write Long Message</button>
            </div>
        </div>
    )
}

export default Success